import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueGtag from 'vue-gtag';
import VueYouTubeEmbed from 'vue-youtube-embed'

// Use Google Analytics

Vue.use(VueGtag, {
    config: {id: 'G-L3F12NSJGQ'} // first 'G-MLT0CFEKCG'
});

// Use YouTube video embed plugin, see https://github.com/kaorun343/vue-youtube-embed
Vue.use(VueYouTubeEmbed)

// Disable the development mode debug message

Vue.config.productionTip = false;

// Create the Vue instance for our application

new Vue({
    vuetify,
    render: h => h(App),
}).$mount('#app')
