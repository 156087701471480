export default {
    methods: {

        observeEvent(category, action, label, value) {
            let eventParams = {
                event_category: category,
                event_label: label,
            }
            if (isNaN(value)) {
                this.debug('Event Category: ' + category + ', Action: ' + action + ', Label: ' + label);
            } else {
                eventParams.event_value = value;
                this.debug('Event Category: ' + category + ', Action: ' + action + ', Label: ' + label, ', Value: ' + value);
            }
            // this.debug(JSON.stringify(eventParams));
            this.$gtag.event(action, eventParams)
        },

        observeAndGoToId(action, label, id) {
            const navTarget = '#' + id;
            const category = 'Navigate'
            label += ' -> #' + id;
            this.observeEvent(category, action, label);
            this.$vuetify.goTo(navTarget);
        },

        observeAndGoToUrl(action, label, url) {
            const category = 'Navigate'
            this.observeEvent(category, action, label);
            window.open(url, "_blank");
        },

        observePanelClick(action, label) {
            let value;
            const category = 'Learn';
            const firstChar = parseInt(label[0]);
            if (typeof firstChar === 'number') {
                value = firstChar;
            }
            this.observeEvent(category, action, label, value);
        },

        observeVideoStateChange(action, label) {
            const category = 'Video';
            this.observeEvent(category, action, label);
        },

        debug(message) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('DEBUG: ' + message);
            }
        }
    }
};
