<template>
  <v-app id="top">
    <v-app-bar
        app
        color="white"
        height="100"
    >

      <v-avatar
          class="mr-3"
          color="grey lighten-3"
          size="90"
      >
        <v-img
            contain
            max-height="85%"
            :src="pdtLogo"
        ></v-img>
      </v-avatar>

      <v-toolbar-title class="font-weight-black headline">
        <a href="/">{{ toolBarTitle }}</a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Menu -->
      <v-menu
          left
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>

          <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              @click="observeAndGoToId('Menu Click', item.label, item.id)"
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>

        </v-list>
      </v-menu>

    </v-app-bar>

    <v-main>
      <PDTPools/>
    </v-main>

    <v-footer
        class="justify-center text--white"
        :color="footerColor"
        height="100"
    >
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        {{ footer }}
        <div class="credits">
          Photo by <a target="_blank" href="https://devinatsatt.smugmug.com/">Devin Atsatt Photography</a>.
          Logo designed by <a target="_blank" href="http://www.freepik.com"
                              @click="observeEvent('External Link','Click', 'Devin Atsatt Photography')"
        >Freepik.</a>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<style>
@import './assets/pdt.css';
</style>

<script>

import observations from "@/assets/observations";
import PDTPools from './components/PDTPools';

export default {
  name: 'App',
  mixins: [observations],
  components: {
    PDTPools,
  },

  data: () => ({
    toolBarTitle: "Stake Pool PDT1",
    pdtLogo: "wave.svg",
    menuItems: [
      {label: 'Get Started', id: 'how-to-stake'},
      {label: 'About Us', id: 'about-us'},
      {label: 'Contact Us', id: 'contact-us'},
      {label: 'Back to top', id: 'top'},
    ],
    cardanoLogo: "cardano-ada-logo.svg",
    footerColor: "grey darken-4",
    footerColor2: "blue-grey darken-2",
    footer: "© 2021 Pacific Digital Technologies",
  }),
};
</script>
