<template>
  <v-main class="pa-0">
    <!-- Landing -->
    <section id="landing">
      <v-row no-gutters>
        <v-img
            :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            :src="landingImage"
        >
          <v-theme-provider dark>
            <v-container fill-height pa-0>

              <v-row
                  align="center"
                  :class="landingTextColor"
                  justify="center"
                  valign-self="end"
                  class="mx-auto"
              >
                <v-col
                    class="text-right"
                    cols="12"
                    tag="h1"
                >

                  <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                      class="font-weight-black pt-16"
                  >
                  {{ landingTitle }}
                </span>

                  <br>
                  <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']"
                      class="font-weight-light"
                  >
                    {{ landingSubtitle }}
                </span>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col>
                  <v-btn
                      class="align-self-right light-green"
                      block
                      depressed
                      elevation="5"
                      raised
                      rounded
                      @click="observeAndGoToId('Button Click', 'Get Started', 'how-to-stake')"
                  >
                      <span class="white--text font-weight-black">
                      Get Started!
                        </span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                  align="start"
                  class="white--text mt-auto"
                  justify="center"
              >
                <v-col
                    class="text-center"
                    cols="12"
                    tag="h1"
                >
                  <v-btn
                      class="align-self-center font-weight-black mb-1"
                      fab
                      outlined
                      @click="observeAndGoToId('Button Click', 'Next Page', 'how-to-stake')"
                  >

                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <!-- How to Stake -->
    <section id="how-to-stake">
      <v-spacer class="pt-12"></v-spacer>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Stake Your ADA
          <v-btn
              x-small
              color="blue-grey lighten-5"
              @click="observeAndGoToId('Button Click', 'Stake Your Ada', 'top')"
          >
            ^
          </v-btn>
        </h2>

        <v-responsive
            class="mx-auto mb-8"
            width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>
        <div class='ml-4 pb-2 font-weight-light'>
        </div>
        <v-container class='ma-2 pb-8 font-weight-light'>
          <v-expansion-panels focusable>
            <v-expansion-panel
                v-for="(step, i) in stakingSteps"
                :key="i"
            >
              <v-expansion-panel-header
                  class='title font-weight-light'
                  v-on:click="observePanelClick( 'Open Panel', step.label)"
              >
                <b>{{ step.label }}</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-html="step.content">
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
        <v-container class='ma-2 pb-8 font-weight-light'>

          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header
                  class='subtitle-1 font-weight-light'
                  @click="observeVideoPanelClick( 'Open Panel', 'Video Walk-Through')"
              >
                <b>{{ video.label }}</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4">
                  <p>
                    This video goes by a little fast so you may need to rewind a few times.
                  </p>
                  <youtube
                      video-id="OUZKSS_cJIE"
                      host="https://www.youtube-nocookie.com"
                      :player-vars='{rel: 0, start: 20, autoplay: 0, modestbranding: 1}'
                      @ready="ready"
                      @cued="cued"
                      @playing="playing"
                      @paused="paused"
                      @buffering="buffering"
                      @ended="ended"
                  >
                  </youtube>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-container>
      </v-container>
    </section>


    <!-- About Us -->
    <section id="about-us">
      <v-spacer class="pt-12 fill-height"></v-spacer>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          ABOUT US
          <v-btn
              x-small
              color="blue-grey lighten-5"
              @click="observeAndGoToId('Button Click', 'About Us', 'top')"
          >
            ^
          </v-btn>
        </h2>

        <v-responsive
            class="mx-auto mb-8"
            width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
            class="mx-auto title font-weight-light mb-8 text-left"
            max-width="720"
        >
          <p>
            We’re a team of seasoned IT professionals, each with over 30 years of experience in the field. We’ve worked for some
            of the biggest names in tech, as well as for companies in the finance, bioengineering, and energy industries. We’ve
            built enterprise scale cloud services, meeting very strict security requirements.
          </p>
          <p>
            The <a target="_blank" href="https://why.cardano.org/en/introduction/motivation/">goals and mission</a> of the
            <a target="_blank" href="https://cardano.org">Cardano project</a> are close to our hearts. We’ve invested
            both our own time as well as our money into helping secure the success of the project. Running the best stake pool
            possible is part of our commitment to making the Cardano network everything it can be.
          </p>
          <p>
            The cloud-managed infrastructure running our pool is redundant, secure, geo-diverse, and highly available. We perform
            rolling upgrades and maintenance using standby resources to avoid downtime.
          </p>
          <br>
          <div id="contact-us"/>
          Questions or comments?
          <v-btn
              class="mx-2"
              dark
              small
              color="#0088cc"
              @click="observeAndGoToUrl('Button Click', 'Telegram', telegramUrl)"
          >
            <v-icon dark>
              mdi-telegram
            </v-icon>
             PDTPools
          </v-btn>
          <v-btn
              class="mx-2"
              dark
              small
              color="#1DA1F2"
              @click="observeAndGoToUrl('Button Click', 'Twitter', twitterUrl)"
          >
            <v-icon dark>
              mdi-twitter
            </v-icon>
            @PDTPools
          </v-btn>
        </v-responsive>
      </v-container>

      <div class="py-12"></div>
    </section>


  </v-main>
</template>

<style>
@import '../assets/pdt.css';
</style>

<script>
import observations from "@/assets/observations";

export default {
  name: 'PDTPools',
  mixins: [observations],
  data: () => ({

    // Landing

    landingTextColor1: "blue-grey--text text--darken-2",
    landingTextColor: "white--text mx-auto",
    landingTitle: "STAKING NOW",
    landingSubtitle: "Secure ADA stake pool",
    landingBody: "",

    landingImage: "beach-surfer-blue-sky-2235-1001.jpg",
    pdtLogo: "wave.svg",
    cardanoLogo: "cardano-ada-logo.svg",
    twitterUrl: "https://twitter.com/intent/tweet?screen_name=PDTPools&ref_src=twsrc%5Etfw",
    telegramUrl: "https://t.me/PDTPools",

    // Staking Steps

    stakingSteps: [
      {
        label: '1. Install Daedalus',
        content: `
            <div class="ma-4">
            <p>
              Here are the instructions for installing Daedalus, which you'll need to stake your ADA.
            </p>
            <ol>
              <li>Go to <a href="https://daedaluswallet.io/#download">https://daedaluswallet.io/#download</a> and download the
                latest version of Daedalus for your operating system.
              </li>
              <li>After the download has completed, double-click the installer and follow the steps to install.</li>
              <li>Once the installation is complete, launch the Daedalus app using the desktop icon.</li>
              <li>Select your language and click <code>Continue</code>.</li>
              <li>If you agree with the terms of use, tick the box at the bottom of the popup to agree, then click
              <code>Continue</code>.</li>
              <li>Daedalus will start syncing the blockchain. This could take several hours, depending on your
              internet connection.
              </li>
              <li>Once sync status has reached 100% Daedalus will open and show you the dashboard where you can create a
                new wallet.
              </li>
            </ol>
            <p class="mt-4">
              You are now ready to create a new wallet.
            </p>
            </div>
            `
      },
      {
        label: '2. Create a wallet',
        content:
            `
            <div class="ma-4">
            <p>
              If you don't already have a wallet in Daedalus, you'll need to create one.
            </p>
            <ol>
              <li>From the Daedalus wallet dashboard click <code>Create</code>.</li>
              <li>Give the new wallet a name.</li>
              <li>Create a spending password; this will be required each time you send ADA or stake.</li>
              <li>Click to create the wallet and follow the instructions carefully to record and safeguard the recovery phrase.
                It is <b>CRITICAL</b> that you don't lose this phrase; if you do, you may lose all of your ADA: there is
                <em>no</em> way to reset this as you can with passwords on other systems!
              </li>
              <li>You can always confirm your recovery phrase by clicking on the <code>More</code> button in your wallet, then
                <code>Settings</code> and use the <code>Verify wallet recovery phrase</code> button.
              </li>
            </ol>
            <p class="mt-4">
              You are now ready to fund your new wallet.
            </p>
            </div>
            `
      },
      {
        label: '3. Fund your wallet',
        content:
            `
            <div class="ma-4">
            <p>If you don't already have ADA in your wallet, you'll need to transfer in at least 10 ADA from your exchange (or a
               nice friend) before you can delegate to a pool. We'll assume you are transferring from an exchange, where you'll
               need to use the exchange's withdrawal option along with a receiving address from your new wallet.
            </p>
            <p>
            <b>Get a receiving address</b>
          </p>
          <ol>
            <li>In your wallet, click on the <code>Receive</code> button.</li>
            <li>From the list of addresses, click the <code>Share</code> button beside the first unused address.
            </li>
            <li>Use the <code>Copy address</code> button to copy it to the clipboard.</li>
          </ol>
          <p class="mt-4">
            <b>Withdraw from your exchange</b>
          </p>
          <ol>
            <li>Use the exchange site to prepare a withdrawal, pasting in the address from above. Double check that the
              address is correct by comparing it to the one from your new wallet.
            </li>
            <li>Choose the amount to transfer. You may want to try transferring a small amount first to test the process
              then go back and do it again with the remainder. Please note that all transactions in Cardano cost a small fee.
            </li>
            <li>Hit send (or the equivalent) and... wait, this may take awhile. When it completes, you should see the balance
              change in your new wallet.
            </li>
          </ol>
            <p class="mt-4">
            You are now ready to stake your ADA.
          </p>
          </div>
          `
      },
      {
        label: '4. Stake your ADA',
        content:
            `
            <div class="ma-4">
            <ol>
              <li>In Daedalus, go to the delegation dashboard (the icon on the left that looks like the Cardano logo).</li>
              <li>Click on <code>Stake pools</code> to see the available stake pools (this may take awhile to populate
                on first access).
              </li>
              <li>Find a pool you'd like to delegate to and note the ticker name. We would <em>greatly</em> appreciate it if you
                would choose our pool, <b>PDT1</b>, but of course you are free to choose any pool.
              </li>
              <li>Click on <code>Delegation center</code> and note the three squares on the right that all say "UNDELEGATED". If
                  you hover the cursor over the last one on the right, you see it change to contain an active
                  <code>DELEGATE</code> button: click it, read the text and click <code>Continue</code>.
              </li>
              <li>Your new wallet should be selected, so just click <code>Continue</code>.</li>
              <li>Here, type in the ticker name for the pool you selected above into the search, e.g. <b>PDT1</b>.</li>
              <li>Click on the pool to select it and then click <code>Continue</code>.</li>
              <li>Finally, type in your spending password and click <code>Confirm</code> to complete the delegation.</li>
            </ol>
            <p class="mt-4">
              Congratulations!
            </p>
            </div>
            `
      },
    ],
    video: {
      label: 'Or, if you prefer, you can watch a video that takes you through the process.',
      startTime: 20,
      isPanelOpen: false,
      isPaused: true,
      isStopped: false,
      emitEvents: true,
    },
  }),

  methods: {

    doPause() {
      if (this.player && !this.isPaused) {
        this.debug('-- pausing --');
        this.emitEvents = false;
        this.player.pauseVideo();
        this.emitEvents = true;
        this.isPaused = true;
        this.debug('-- paused --');
      }
    },

    doStop() {
      if (this.player && !this.isStopped) {
        this.debug('-- stopping --');
        this.emitEvents = false;
        this.player.seekTo(this.startTime, true);
        this.player.stopVideo();
        this.emitEvents = true;
        this.isStopped = true;
        this.debug('-- stopped --');
      }
    },

    observeVideoPanelClick(action, label) {
      this.debug(' -- video panel click begin, open: ' + this.video.isPanelOpen + ', paused: ' + this.isPaused + ', stopped: ' + this.isStopped);
      var open = this.video.isPanelOpen;
      this.isPaused = false;
      this.isStopped = false;
      this.doPause();
      this.doStop();
      open = !open;
      this.video.isPanelOpen = open;
      if (open) {
        this.observePanelClick(action, label);
      }
      this.debug('-- video panel end, open: ' + this.video.isPanelOpen + ', paused: ' + this.isPaused + ', stopped: ' + this.isStopped);
    },

    observeVideoEvent(action, label) {
      this.debug('action: ' + action + ', label: ' + label + ', emitEvents: ' + this.emitEvents);
      if (this.emitEvents) {
        this.observeVideoStateChange(action, label);
      }
    },

    ready(event) {
      this.player = event.target;
      this.emitEvents = true;
      this.debug('Video ready: ' + event.target);
    },

    buffering() {
      //this.observeVideoEvent('Buffering', 'Video Walk-Through');
    },

    cued() {
      if (!this.isStopped) {
        this.observeVideoEvent('Cued', 'Video Walk-Through');
      }
    },

    playing() {
      if (!this.isStopped) {
        this.observeVideoEvent('Playing', 'Video Walk-Through');
      }
    },

    paused() {
      this.isPaused = true;
      this.observeVideoEvent('Paused', 'Video Walk-Through');
    },

    ended() {
      this.isStopped = false;
      this.doStop();
      this.observeVideoEvent('Ended', 'Video Walk-Through');
    },
  }
}
</script>
